






























































































































import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import EmphasysRow from "@/components/Atoms/emphasys-row.vue";
import { mapActions, mapMutations } from "vuex";

export default defineComponent({
  components: {
    EmphasysRow
  },
  methods: {
    ...mapActions("refund", [
      "setDontShowReceiptDeliveryNoticeAgain",
      "setShowReceiptDeliveryNoticeOnInit",
      "getIsRefundOdontologico"
    ]),
    ...mapMutations("refund", ["SET_NEW_REFUND"])
  },
  setup() {
    const context = getCurrentInstance().proxy;
    const inRefundNew = context.$route.path.includes("refund-new");
    const notShowAgainCheck = ref(false);

    onMounted(() => {
      context.$root.$emit("hide-back-button");
      context.$root.$emit("hide-header-options");
      context.$root.$emit("hide-loading");
    });

    const nextPage = async () => {
      if (inRefundNew) {
        if (notShowAgainCheck.value) {
          context.$root.$emit("show-loading");
          await context["setDontShowReceiptDeliveryNoticeAgain"]();
          context.$root.$emit("hide-loading");
        }

        const result = await context["getIsRefundOdontologico"]();
        if (result) {
          context["SET_NEW_REFUND"]({ type: false });
          context.$router.push({
            name: "refund.new.create.terms"
          });
        } else {
          context.$router.push({
            name: "refund.new.create"
          });
        }
      } else {
        context.$root.$emit("show-loading");
        await context["setShowReceiptDeliveryNoticeOnInit"]();
        context.$root.$emit("hide-loading");
        context.$router.push("/");
      }
    };

    return {
      nextPage,
      inRefundNew,
      notShowAgainCheck
    };
  }
});
