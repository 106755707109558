import { render, staticRenderFns } from "./receipt-delivery-notice.vue?vue&type=template&id=5fa12fbb&scoped=true&"
import script from "./receipt-delivery-notice.vue?vue&type=script&lang=ts&"
export * from "./receipt-delivery-notice.vue?vue&type=script&lang=ts&"
import style0 from "./receipt-delivery-notice.vue?vue&type=style&index=0&id=5fa12fbb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa12fbb",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox})
